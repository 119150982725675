<template>
  <b-card>
    <div>
      <!-- search input -->
      <div class="custom-search d-flex align-items-center justify-content-end">
        <div
          class="d-flex flex-column flex-sm-row align-items-center mb-1 justify-content-around"
        >
          <template v-if="$permissionAbility(LEAD_SOURCE_CREATE, permissions)">
            <b-button
              class="flex-shrink-0"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              v-on:click="showModal"
            >
              Create Source
            </b-button>
          </template>
        </div>
      </div>

      <!-- table -->
      <vue-good-table
          styleClass="vgt-table striped"
          mode="remote"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
          :totalRows="totalRecords"
          :rows="rows"
          :sort-options="{
          enabled: false,
          multipleColumns: true,
          initialSortBy: [{ field: 'id', type: 'desc' }],
        }"
          :columns="columns"
          :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <template slot="table-row" slot-scope="props">

          <!-- Column: Status -->
          <span v-if="props.column.field === 'status_format'">
            <b-badge :variant="statusVariant(props.row.status)">
              {{ props.row.status ? "Active" : "Inactive" }}
            </b-badge>
          </span>

          <template v-if="props.column.field === 'format_lead_count'">

            <b-badge class="cursor-pointer" @click="filterLead(props.row.id)" variant='light-primary'>
              {{ props.row.lead_count }}
            </b-badge>

          </template>

          <!-- Column: Action -->
          <span v-if="props.column.field === 'action'">

            <template v-if="$permissionAbility(LEAD_SOURCE_EDIT, permissions)">
              <span @click="onShow(props.row)">
                <feather-icon
                    v-b-tooltip.hover
                    icon="Edit2Icon"
                    class="mr-50 custom-icon cursor-pointer"
                    title="Edit"
                    variant="warning"
                    size="16"
                />
              </span>
            </template>

            <template
                v-if="$permissionAbility(LEAD_SOURCE_DELETE, permissions)"
            >
              <span @click="onDelete(props.row)">
                <feather-icon
                    v-b-tooltip.hover
                    icon="TrashIcon"
                    class="mr-50 custom-icon cursor-pointer"
                    variant="danger"
                    title="Delete"
                    size="16"
                />
              </span>
            </template>

          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Showing 1 to </span>
              <b-form-select
                v-model="pageLength"
                :options="['10', '25', '50', '100', '500']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>

    <b-modal
      id="modal-source-form"
      centered
      :title="modelType == 'editModel' ? 'Edit Source' : 'Create Source'"
      hide-footer
      @hidden="hiddenModal"
      no-close-on-backdrop
    >
      <validation-observer ref="leadSourceValidation">
        <b-form v-on:submit.prevent="validationForm">
          <!-- name -->
          <b-form-group label="Title" label-for="name">
            <validation-provider
              #default="{ errors }"
              name="title"
              vid="name"
              rules="required|max:255"
            >
              <b-form-input
                id="name"
                type="text"
                v-model="name"
                :state="errors.length > 0 ? false : null"
                placeholder="Source"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- select status type-->
          <b-form-group label="Status" label-for="status">
            <ValidationProvider name="status" v-slot="{ errors }" vid="status">
              <v-select
                id="status"
                placeholder="Select Status"
                v-model="selectStatusValue"
                :options="statusValueOption"
                :reduce="(option) => option.value"
                label="name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>

          <!-- loading button -->
          <template v-if="isLeadSourceFormSubmitLoading">
            <b-button class="float-right" variant="primary" disabled>
              <b-spinner small />
              Loading...
            </b-button>
          </template>

          <!-- submit button -->
          <template v-else>
            <b-button
              type="submit"
              class="float-right"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
            >
              Submit
            </b-button>
          </template>
        </b-form>
      </validation-observer>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BForm,
  BModal,
  BSpinner, VBTooltip,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, max, min } from "@validations";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import {
  LEAD_SOURCE_CREATE,
  LEAD_SOURCE_EDIT,
  LEAD_SOURCE_DELETE,
} from "@/helpers/permissionsConstant";

export default {
  name: "LeadSourceView",
  components: {
    BForm,
    BButton,
    BCard,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BSpinner,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      LEAD_SOURCE_CREATE,
      LEAD_SOURCE_EDIT,
      LEAD_SOURCE_DELETE,
      leadSourceId: "",
      modelType: "",
      name: "",
      selectStatusValue: "",
      statusValueOption: [
        {
          name: "Active",
          value: true,
        },
        {
          name: "Inactive",
          value: false,
        },
      ],
      pageLength: 10,
      columns: [
        {
          label: "Title",
          field: "name",
          sortable: false,
        },
        {
          label: "Lead Counts",
          field: "format_lead_count",
          sortable: false,
        },
        {
          label: "Status",
          field: "status_format",
          formatFn: this.formatFnTableStatus,
          sortable: false,
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
        },
      ],
      rows: [],
      isLeadSourceFormSubmitLoading: false,

      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [],
        page: 1,
        perPage: 10,
      },
    };
  },

  computed: {
    ...mapGetters({
      permissions: "userModule/getPermissions",
    }),
    statusVariant() {
      const statusColor = {
        true: "light-success",
        false: "light-danger",
      };

      return (status) => statusColor[status];
    },
  },

  async created() {
    try {
      this.loadItems();
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          icon: "BellIcon",
          variant: "danger",
          text: error?.response?.data?.message,
        },
      });
    }
  },

  methods: {
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        sort: params,
      });
      this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },
    filterLead(id){
      this.$store.dispatch("leadModule/setFilter", {
        key: "filterSourceId",
        value: id,
      });
      this.$router.push({ name: 'admin-lead-management' });
    },
    formatFnTableStatus(status) {
      return status ? "Active" : "Inactive";
    },

    showModal() {
      this.$bvModal.show("modal-source-form");
    },

    hiddenModal() {
      this.$bvModal.hide("modal-source-form");
      this.resetModal();
    },

    resetModal() {
      this.modelType = "";
      this.name = "";
      this.leadSourceId = "";
      this.selectStatusValue = "";
    },

    onShow(value) {
      this.modelType = "editModel";
      this.leadSourceId = value.id;
      this.name = value.name;
      this.selectStatusValue = value.status;
      this.showModal();
    },

    async onDelete(row) {
      this.$swal({
        title: "Warning!",
        text: `Are You Sure You Want To Delete ${row.name}?`,
        icon: "warning",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Ok",
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.$api.delete(`/api/lead-sources/${row.id}`);

            await this.loadItems();

            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "Successfully Deleted",
              },
            });
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                variant: "danger",
                text: error?.response?.data?.message,
              },
            });
          }
        }
      });
    },
    async getSourceItems(params) {
      return await this.$api.get(
          "api/lead-sources",
          {
            params: {
              show: params.show,
              page: params.page,
              sort: params.sort,
              q: params.q,
            },
          }
      );

    },
    async loadItems() {
      try {
        const [leadSources] = await Promise.all([this.getSourceItems(
            {
              show: this.serverParams.perPage,
              page: this.serverParams.page,
              sort: this.serverParams.sort,
              q: this.searchTerm,
            }
        )]);

        const data = leadSources?.data?.data;
        const meta = leadSources?.data?.meta;
        this.totalRecords = meta?.pagination?.total;
        this.rows = data;

      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
    validationForm: async function () {
      this.$refs.leadSourceValidation.validate().then(async (success) => {
        if (success) {
          try {
            this.$refs.leadSourceValidation.reset();
            if (this.modelType == "editModel") {
              this.isLeadSourceFormSubmitLoading = true;
              await this.$api.put(`/api/lead-sources/${this.leadSourceId}`, {
                name: this.name,
                status: this.selectStatusValue,
              });
              this.isLeadSourceFormSubmitLoading = false;
              this.loadItems();

              this.hiddenModal();

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: "Lead Source successfully updated",
                },
              });
            } else {
              this.isLeadSourceFormSubmitLoading = true;
              await this.$api.post("/api/lead-sources", {
                name: this.name,
                status: this.selectStatusValue,
              });
              this.isLeadSourceFormSubmitLoading = false;
              this.hiddenModal();

              this.loadItems();

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: "Lead Source successfully created",
                },
              });
            }
          } catch (error) {
            this.isLeadSourceFormSubmitLoading = false;
            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: error?.response?.data?.message,
                },
              });
            }

            if (error?.response?.data?.errors) {
              this.$refs.leadSourceValidation.setErrors(
                error?.response?.data?.errors
              );
            }
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>

